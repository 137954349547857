/* src/TrainDetailsModal.css */
.train-details-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    animation: fadeIn 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .train-details-modal-content {
    background-color: rgba(20, 20, 40, 0.7);
    padding: 2.5rem;
    border-radius: 16px;
    width: 90%;
    max-width: 700px; /* 增加最大寬度 */
    max-height: 90vh; /* 增加最大高度 */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 30px rgba(0, 229, 255, 0.3), 0 0 60px rgba(255, 0, 221, 0.3);
    position: relative;
    animation: modalAppear 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  
  @keyframes modalAppear {
    from {
      opacity: 0;
      transform: scale(0.9) translateY(20px);
    }
    to {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  
  .train-details-modal-content h2 {
    margin-top: 0;
    background: linear-gradient(45deg, #00e5ff, #ff00dd);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  .train-info {
    margin-bottom: 1.5rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    padding: 1.25rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    animation: fadeIn 0.5s 0.1s cubic-bezier(0.19, 1, 0.22, 1) both;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* 使用網格布局 */
    gap: 0.5rem;
  }
  
  .train-info p {
    margin: 0.75rem 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding-bottom: 0.75rem;
  }
  
  .train-info p:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .train-info p strong {
    color: #00e5ff;
    font-weight: 500;
  }
  
  .stop-times-container {
    flex-grow: 1;
    overflow-y: auto;
    margin: 1rem 0;
    animation: fadeIn 0.5s 0.2s cubic-bezier(0.19, 1, 0.22, 1) both;
    min-height: 400px; /* 設置最小高度 */
    max-height: 500px; /* 設置最大高度 */
  }
  
  .stop-times {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.02);
    height: 100%; /* 確保高度填滿容器 */
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5rem 0;
  }
  
  /* 站點時間線樣式 */
  .timeline {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 4px;
    background: linear-gradient(to bottom, rgba(0, 229, 255, 0.5), rgba(255, 0, 221, 0.5));
    z-index: 1;
  }
  
  .stop-time {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem 1rem 0; /* 右側內邊距 */
    position: relative;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    font-size: 1.05rem; /* 增加字體大小 */
    margin-left: 60px; /* 為站點圓圈和連接線留出空間 */
  }
  
  /* 站點圓圈樣式 */
  .station-circle {
    position: absolute;
    left: -26px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #1e1e30;
    border: 3px solid rgba(255, 255, 255, 0.3);
    z-index: 2;
    transition: all 0.3s ease;
  }
  
  .stop-time.past .station-circle {
    border-color: rgba(0, 229, 255, 0.7);
    background-color: rgba(0, 229, 255, 0.2);
  }
  
  .stop-time.current .station-circle {
    border-color: #00e5ff;
    background-color: rgba(0, 229, 255, 0.5);
    box-shadow: 0 0 15px rgba(0, 229, 255, 0.7);
    transform: scale(1.2);
  }
  
  /* 火車位置指示器 */
  .train-indicator {
    position: absolute;
    left: -39px;
    width: 40px;
    height: 20px;
    z-index: 5; /* 提高 z-index 值，確保火車在最上層 */
    transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.7)); /* 修改陰影顏色為白色 */
    animation: trainPulse 2s infinite alternate;
  }

  .train-indicator-bg {
  position: absolute;
  left: -43px; /* 稍微比火車指示器寬一些 */
  width: 48px;
  height: 28px;
  background-color: rgba(20, 20, 40, 0.9); /* 與模態框背景相似的顏色 */
  z-index: 4; /* 在時間線上方，但在火車下方 */
  border-radius: 4px;
}

  @keyframes trainPulse {
    0% { transform: scale(1); }
    100% { transform: scale(1.1); }
  }
  
  .stop-time {
    opacity: 0.8;
  }
  
  .stop-time.past {
    opacity: 0.6;
  }
  
  .stop-time.current {
    opacity: 1;
    font-weight: 600;
    font-size: 1.1rem; /* 當前站點字體略大 */
  }
  
  .stop-time:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  /* 站名和時間的樣式 */
  .station-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
  }
  
  .station-name {
    font-weight: 500;
    flex: 1;
  }
  
  .time-info {
    text-align: right;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .scheduled-time {
    font-weight: normal;
  }
  
  .delayed-time {
    color: #ff9500;
    font-size: 0.9em;
    margin-top: 0.2rem;
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
  }
  
  .close-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: rotate(90deg);
    color: #00e5ff;
  }
  
  /* 自定義滾動條樣式 */
  .train-details-modal-content::-webkit-scrollbar,
  .stop-times-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .train-details-modal-content::-webkit-scrollbar-track,
  .stop-times-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  
  .train-details-modal-content::-webkit-scrollbar-thumb,
  .stop-times-container::-webkit-scrollbar-thumb {
    background: linear-gradient(#00e5ff, #ff00dd);
    border-radius: 10px;
  }
  
  .train-details-modal-content::-webkit-scrollbar-thumb:hover,
  .stop-times-container::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#ff00dd, #00e5ff);
  }
  
  /* 添加模態框關閉動畫 */
  .train-details-modal-overlay.closing {
    animation: fadeOut 0.3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  }
  
  .train-details-modal-overlay.closing .train-details-modal-content {
    animation: modalDisappear 0.3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  
  @keyframes modalDisappear {
    from { 
      opacity: 1;
      transform: scale(1) translateY(0);
    }
    to { 
      opacity: 0;
      transform: scale(0.95) translateY(20px);
    }
  }
  
  /* 站點列表標題 */
  .stop-times-header {
    padding: 1rem 1.5rem 1rem 60px;
    background: linear-gradient(90deg, rgba(0, 229, 255, 0.1), rgba(255, 0, 221, 0.1));
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 4;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  
  /* 響應式設計 */
  @media (max-width: 768px) {
    .train-details-modal-content {
      padding: 2rem 1.5rem;
      width: 95%;
    }
    
    .stop-time {
      padding: 0.85rem 1rem 0.85rem 0;
      font-size: 1rem;
      margin-left: 50px;
    }
    
    .station-circle {
      left: -25px;
      width: 14px;
      height: 14px;
    }
    
    .timeline {
      left: 35px;
    }
    
    .train-indicator {
      left: -35px;
      width: 30px;
      height: 15px;
    }
    
    .train-info {
      grid-template-columns: 1fr;
    }
    
    .stop-times-container {
      min-height: 350px;
    }
    
    .stop-times-header {
      padding-left: 50px;
    }
  }
  
  @media (max-width: 480px) {
    .train-details-modal-content {
      padding: 1.75rem 1rem;
    }
    
    .train-details-modal-content h2 {
      font-size: 1.3rem;
    }
    
    .train-info p {
      flex-direction: column;
      gap: 0.25rem;
    }
    
    .stop-time {
      padding: 0.75rem 0.75rem 0.75rem 0;
      font-size: 0.95rem;
      margin-left: 40px;
    }
    
    .station-circle {
      left: -22px;
      width: 12px;
      height: 12px;
      border-width: 2px;
    }
    
    .timeline {
      left: 30px;
      width: 3px;
    }
    
    .train-indicator {
      left: -30px;
      width: 24px;
      height: 12px;
    }
    
    .stop-times-header {
      padding-left: 40px;
    }
  }