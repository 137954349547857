@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #00e5ff;
  --secondary-color: #ff00dd;
  --background-color: #0a0a1a;
  --card-bg: rgba(20, 20, 40, 0.5);
  --text-color: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --border-radius: 16px;
  --transition-slow: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  --transition-normal: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  --transition-fast: 0.15s cubic-bezier(0.19, 1, 0.22, 1);
  --shadow-normal: 0 10px 30px rgba(0, 0, 0, 0.25);
  --shadow-hover: 0 15px 40px rgba(0, 0, 0, 0.35);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

/* Main app container */
.app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: 
    radial-gradient(circle at 10% 20%, rgba(0, 229, 255, 0.15) 0%, transparent 30%),
    radial-gradient(circle at 90% 80%, rgba(255, 0, 221, 0.15) 0%, transparent 30%);
  padding: 2rem 1rem;
  position: relative;
  overflow: hidden;
}

.app::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 150%;
  background: linear-gradient(
    45deg,
    rgba(0, 229, 255, 0.05) 0%,
    rgba(255, 0, 221, 0.05) 100%
  );
  top: -25%;
  left: -25%;
  animation: rotate 60s linear infinite;
  z-index: -1;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Main container with glass effect */
.container {
  background-color: var(--card-bg);
  border-radius: var(--border-radius);
  padding: 2.5rem;
  box-shadow: var(--shadow-normal);
  max-width: 800px;
  width: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all var(--transition-normal);
  animation: fadeIn 0.8s var(--transition-slow);
  position: relative;
  overflow: hidden;
}

.container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(0, 229, 255, 0.05) 0%,
    rgba(255, 0, 221, 0.05) 100%
  );
  z-index: -1;
}

.container:hover {
  box-shadow: var(--shadow-hover);
  transform: translateY(-5px);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Typography */
h1, h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all var(--transition-normal);
  position: relative;
}

h1 {
  font-size: 2.2rem;
  margin-bottom: 2.5rem;
}

h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  border-radius: 3px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

/* Form elements */
.search-container {
  display: flex;
  margin-bottom: 2rem;
  position: relative;
  transition: all var(--transition-normal);
}

input[type="text"] {
  flex-grow: 1;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.08);
  color: var(--text-color);
  outline: none;
  transition: all var(--transition-normal);
  font-family: 'Poppins', sans-serif;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

input[type="text"]:focus {
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 0 0 2px rgba(0, 229, 255, 0.3);
}

button {
  padding: 0.85rem 1.75rem;
  font-size: 1rem;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: var(--background-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all var(--transition-normal);
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.search-container button {
  position: absolute;
  right: 5px;
  top: 5px;
}

button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, var(--secondary-color), var(--primary-color));
  transition: all var(--transition-normal);
  z-index: -1;
}

button:hover:not(:disabled)::before {
  left: 0;
}

button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.3);
}

button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Results and info display */
.result {
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  margin-top: 2rem;
  transition: all var(--transition-slow);
  animation: fadeIn 0.5s var(--transition-slow);
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  transition: all var(--transition-normal);
  max-height: 600px;
  overflow-y: auto;
  padding-right: 10px;
}

.info-item {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: var(--border-radius);
  padding: 1.25rem;
  transition: all var(--transition-normal);
  animation: fadeIn 0.5s calc(var(--i, 0) * 0.1s) var(--transition-slow);
  border: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  position: relative;
}

.info-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px) scale(1.02);
}

.info-item:active {
  transform: translateY(-2px) scale(1.01);
}

.info-item::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: var(--border-radius);
  padding: 1px;
  background: linear-gradient(45deg, transparent, rgba(0, 229, 255, 0.3), rgba(255, 0, 221, 0.3), transparent);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.info-item:hover::after {
  opacity: 1;
}

.label {
  font-weight: 500;
  color: var(--primary-color);
  transition: color var(--transition-normal);
  margin-right: 0.5rem;
}

.value {
  color: var(--text-color);
  transition: color var(--transition-normal);
}

/* Home page */
.home-page {
  text-align: center;
  animation: fadeIn 0.5s var(--transition-slow);
}

.selection-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  transition: all var(--transition-normal);
  flex-wrap: wrap;
}

.selection-buttons button {
  width: 200px;
  padding: 1rem 1.5rem;
  transition: all var(--transition-normal);
}

/* Train details */
.train-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
  transition: all var(--transition-normal);
}

.train-details {
  display: grid;
  gap: 0.5rem;
  transition: all var(--transition-normal);
}

.train-details div {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: all var(--transition-normal);
}

.train-details div:last-child {
  border-bottom: none;
}

/* Messages */
.info-message, .loading, .error {
  text-align: center;
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: var(--border-radius);
  transition: all var(--transition-normal);
  animation: fadeIn 0.5s var(--transition-slow);
}

.loading {
  color: var(--primary-color);
  background-color: rgba(0, 229, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% { left: 100%; }
}

.error {
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
}

.info-message {
  color: var(--secondary-color);
  background-color: rgba(255, 0, 221, 0.1);
}

/* Header with back button */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  transition: all var(--transition-normal);
  position: relative;
}

.back-button {
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.back-button::before {
  content: '←';
  display: inline-block;
  margin-right: 0.25rem;
  transition: transform var(--transition-normal);
}

.back-button:hover::before {
  transform: translateX(-3px);
}

/* Train type badges */
.train-type {
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  font-size: 0.85rem;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.train-type-local {
  background-color: #0072C6;
  box-shadow: 0 0 10px rgba(0, 114, 198, 0.5);
}

.train-type-express {
  background-color: #FF6B00;
  box-shadow: 0 0 10px rgba(255, 107, 0, 0.5);
}

.train-type-tze-chiang {
  background-color: #E60012;
  box-shadow: 0 0 10px rgba(230, 0, 18, 0.5);
}

.train-type-chu-kuang {
  background-color: #00A040;
  box-shadow: 0 0 10px rgba(0, 160, 64, 0.5);
}

.train-type-fu-hsing {
  background-color: #9E005D;
  box-shadow: 0 0 10px rgba(158, 0, 93, 0.5);
}

.train-type-other {
  background-color: #888888;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.5);
}

/* Station dropdown */
.station-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(20, 20, 40, 0.95);
  border: 1px solid rgba(0, 229, 255, 0.3);
  border-radius: var(--border-radius);
  overflow-y: auto;
  z-index: 10;
  transition: all var(--transition-normal);
  opacity: 0;
  max-height: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: var(--shadow-normal);
}

.station-dropdown.open {
  opacity: 1;
  margin-top: 5px;
  max-height: 200px;
}

.station-option {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all var(--transition-fast);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.station-option:last-child {
  border-bottom: none;
}

.station-option:hover {
  background-color: rgba(255, 255, 255, 0.1);
  padding-left: 1.5rem;
}

/* Custom scrollbars */
.station-dropdown::-webkit-scrollbar,
.info-grid::-webkit-scrollbar,
.train-details-modal-content::-webkit-scrollbar,
.stop-times-container::-webkit-scrollbar {
  width: 6px;
}

.station-dropdown::-webkit-scrollbar-track,
.info-grid::-webkit-scrollbar-track,
.train-details-modal-content::-webkit-scrollbar-track,
.stop-times-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.station-dropdown::-webkit-scrollbar-thumb,
.info-grid::-webkit-scrollbar-thumb,
.train-details-modal-content::-webkit-scrollbar-thumb,
.stop-times-container::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--primary-color), var(--secondary-color));
  border-radius: 10px;
}

.station-dropdown::-webkit-scrollbar-thumb:hover,
.info-grid::-webkit-scrollbar-thumb:hover,
.train-details-modal-content::-webkit-scrollbar-thumb:hover,
.stop-times-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(var(--secondary-color), var(--primary-color));
}

/* 頁面過渡動畫 */
.fade-in {
  animation: fadeIn 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.fade-out {
  animation: fadeOut 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none;
}

@keyframes fadeOut {
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(10px); }
}

/* 為各個元素添加延遲動畫 */
.info-item:nth-child(1) { --i: 0; }
.info-item:nth-child(2) { --i: 1; }
.info-item:nth-child(3) { --i: 2; }
.info-item:nth-child(4) { --i: 3; }
.info-item:nth-child(5) { --i: 4; }
.info-item:nth-child(6) { --i: 5; }
.info-item:nth-child(7) { --i: 6; }
.info-item:nth-child(8) { --i: 7; }
.info-item:nth-child(9) { --i: 8; }
.info-item:nth-child(10) { --i: 9; }

/* 脈動動畫用於強調當前項目 */
@keyframes pulse-border {
  0% { box-shadow: 0 0 0 0 rgba(0, 229, 255, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0, 229, 255, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 229, 255, 0); }
}

.stop-time.current {
  animation: pulse-border 2s infinite;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    padding: 2rem 1.5rem;
  }
  
  h1 {
    font-size: 1.8rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  .selection-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .selection-buttons button {
    width: 100%;
    max-width: 250px;
  }
  
  .info-grid {
    grid-template-columns: 1fr;
  }
  
  .search-container {
    flex-direction: column;
  }
  
  .search-container button {
    position: static;
    margin-top: 1rem;
    width: 100%;
  }
  
  input[type="text"] {
    width: 100%;
  }
  
  .header {
    flex-direction: column;
    gap: 1rem;
  }
  
  .back-button {
    align-self: flex-start;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem 1rem;
  }
  
  h1 {
    font-size: 1.6rem;
  }
  
  h2 {
    font-size: 1.3rem;
  }
}

/* 修復滾動和置中問題 */
html, body {
  height: 100%;
  overflow-y: auto;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

.app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 改為 flex-start 而不是 center */
  padding: 2rem 1rem;
  overflow-y: auto; /* 允許 app 容器滾動 */
}

.container {
  margin: 2rem auto; /* 添加上下外邊距 */
  max-height: none; /* 移除任何最大高度限制 */
  overflow: visible; /* 確保內容可見 */
}

.result {
  max-height: none; /* 移除最大高度限制 */
  overflow: visible;
}

.info-grid {
  max-height: none; /* 讓網格自適應內容高度 */
  overflow: visible;
}

/* 確保模態框在滾動時仍然置中 */
.modal-overlay,
.train-details-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 隱藏所有瀏覽器的預設滾動條 */
html, body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* 針對 Chrome, Safari 和 Opera 隱藏滾動條 */
html::-webkit-scrollbar, 
body::-webkit-scrollbar {
  display: none;
}

/* 隱藏 app 容器的滾動條 */
.app {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.app::-webkit-scrollbar {
  display: none;
}

/* 確保容器仍然可以滾動 */
html, body, .app {
  overflow-y: auto;
}

/* 保留自定義滾動條樣式，但只用於內部元素 */
.station-dropdown::-webkit-scrollbar,
.info-grid::-webkit-scrollbar,
.train-details-modal-content::-webkit-scrollbar,
.stop-times-container::-webkit-scrollbar {
  width: 6px;
  display: block; /* 顯示這些元素的滾動條 */
}