/* src/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  animation: fadeIn 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modal-content {
  position: relative;
  background-color: rgba(20, 20, 40, 0.7);
  color: #ffffff;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(0, 229, 255, 0.3), 0 0 60px rgba(255, 0, 221, 0.3);
  text-align: center;
  max-width: 90%;
  width: 400px;
  animation: modalAppear 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.modal-content p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
  color: #00e5ff;
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: scale(0.9) translateY(20px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* 添加關閉動畫 */
.modal-overlay.closing {
  animation: fadeOut 0.3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

.modal-overlay.closing .modal-content {
  animation: modalDisappear 0.3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes modalDisappear {
  from { 
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  to { 
    opacity: 0;
    transform: scale(0.95) translateY(20px);
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 2rem 1.5rem;
    width: 95%;
  }
  
  .modal-content p {
    font-size: 1rem;
  }
}